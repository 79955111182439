.timeline-item-text{
  font-size: 10px;
  margin: 50px;
}
.button-tooltip{
  border: none;
  background: #fff;
}
.Playlist{
  color:#1bc943
}